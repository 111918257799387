import Cart from '@/scripts/models/cart.model'
import Person from '@/scripts/models/person.model'
import TicketConfig from '@/scripts/models/ticketConfig.model'

export default class TicketCartDetailsViewModel {
    constructor() {
        this.ticketConfig = new TicketConfig();
        this.person = new Person();
        this.cart = new Cart();
        this.statusId = 0;
        this.dueDate = '';
        this.ticketType = '';

    }
}